import React from 'react';

const OverviewArea = () =>{

    return (
        <>
            <section className="overview-area ptb-100 pt-0">
                <div className="container">
                    <div className="section-title">
                        {/*<span className="sub-title">Our Services</span>*/}
                        <h2>ჩვენი მთავარი ღირებულება კმაყოფილი კლიენტია</h2>
                        <p>ჩვენ სწორად გვესმის რომ კლიენტთა ნდობის მოსაპოვებლად მნიშვნელოვანია კეთილსინდისირება და მაღალი ხარისხი.</p>
                    </div>

                    <div className="overview-box">
                        <div className="overview-image">
                            <div className="image">
                                <img src="../../assets/img/about-img1.png" alt="image"/>
                            </div>
                        </div>

                        <div className="overview-content">
                            <div className="content">
                                {/*<span className="sub-title">Digital Marketing</span>*/}
                                <h2>ჩვენი გუნდი ნებისმიერ იდეას აქცევს რეალობად</h2>
                                <p>თუ არ გაქვთ  ვებ საიტის, დიზაინისა და ფუნქციონალის ზუსტი იდეა, ჩვენ გვაქვს საკმარისი გამოცდილება, გავაანალიზოთ, შევისწავლოთ  და შემოგთავაზოთ, ყველაზე ეფექტური გადაწყვეტილება.</p>

                                <div className="features-text">
                                    <h4><i className="flaticon-tick"/> მაღალი პროფესიონალიზმი</h4>
                                    {/*<p>No fake products and services. The customer is king, their lives and needs are
                                        the inspiration.</p>*/}
                                </div>

                                <div className="features-text">
                                    <h4><i className="flaticon-tick"/>უწყვეტი პროფესიული განვითარება</h4>
                                    {/*<p>No fake products and services. The customer is king, their lives and needs are
                                        the inspiration.</p>*/}
                                </div>

                                <div className="features-text">
                                    <h4><i className="flaticon-tick"/> თანამშრომლობა, გუნდურობა და გამჭვირვალობა</h4>
                                    {/*<p>No fake products and services. The customer is king, their lives and needs are
                                        the inspiration.</p>*/}
                                </div>
                            </div>
                        </div>


                    </div>

                    {/*<div className="overview-box">
                        <div className="overview-image">
                            <div className="image">
                                <img src="../../assets/img/why-choose-img1.png" alt="image"/>
                            </div>
                        </div>

                        <div className="overview-content">
                            <div className="content right-content">
                                <span className="sub-title">Design & Development</span>
                                <h2>Solving problems, building brands</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication.</p>

                                <ul className="features-list">
                                    <li><span><i className="fas fa-check"/> Recommender systems</span></li>
                                    <li><span><i className="fas fa-check"/> Demand prediction</span></li>
                                    <li><span><i className="fas fa-check"/> Omnichannel analytics</span></li>
                                    <li><span><i className="fas fa-check"/> Lead generation</span></li>
                                    <li><span><i className="fas fa-check"/> Dedicated Developers</span></li>
                                    <li><span><i className="fas fa-check"/> 24/7 Support</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="overview-box">
                        <div className="overview-content">
                            <div className="content">
                                <span className="sub-title">Social Media Marketing</span>
                                <h2>We are the next generation of the advertising world</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication.</p>

                                <div className="features-text">
                                    <h4><i className="flaticon-tick"/> Core Development</h4>
                                    <p>No fake products and services. The customer is king, their lives and needs are
                                        the inspiration.</p>
                                </div>

                                <div className="features-text">
                                    <h4><i className="flaticon-tick"/> Define Your Choices</h4>
                                    <p>No fake products and services. The customer is king, their lives and needs are
                                        the inspiration.</p>
                                </div>
                            </div>
                        </div>

                        <div className="overview-image">
                            <div className="image">
                                <img src="../../assets/img/features-image/1.png" alt="image"/>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="overview-box">
                        <div className="overview-image">
                            <div className="image">
                                <img src="../../assets/img/features-image/2.png" alt="image"/>
                            </div>
                        </div>

                        <div className="overview-content">
                            <div className="content right-content">
                                <span className="sub-title">SEO Consultancy</span>
                                <h2>We make smart, good-looking things</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication.</p>

                                <ul className="features-list">
                                    <li><span><i className="fas fa-check"/> Recommender systems</span></li>
                                    <li><span><i className="fas fa-check"/> Demand prediction</span></li>
                                    <li><span><i className="fas fa-check"/> Omnichannel analytics</span></li>
                                    <li><span><i className="fas fa-check"/> Lead generation</span></li>
                                    <li><span><i className="fas fa-check"/> Dedicated Developers</span></li>
                                    <li><span><i className="fas fa-check"/> 24/7 Support</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </section>
        </>
    )
}

export default OverviewArea;
