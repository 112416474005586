
import React, {useEffect, useState} from "react";
import './sidebar.css';

const SidebarModal = ({onClose,show}) =>{
    const [showSidebar,setShowSidebar] = useState(false);

    useEffect(()=>{
        setShowSidebar(show)
    },[show])

    return (
        <>
            <div className={`sidebar-modal ${showSidebar?'active':''}`}>
                <div className="sidebar-modal-inner">
                    <div className="sidebar-about-area">
                        <div className="title">
                            <h2><img src="../../assets/img/logo.png"/></h2>
                            <h3>ჩვენს შესახებ</h3>
                            <p>ჩვენი მისიაა დავეხმაროთ კლიენტებს ბიზნესის შექმნასა და ზრდა - განვითარებასთან დაკავშირებული გამოწვევების ოპტიმალურად გადაწყვეტაში, ვუზრუნველყოთ მაღალი ხარისხის მომსახურება და ეს ყოველივე გავაკეთოთ პროფესიონალურად და დროულად.</p>
                        </div>
                    </div>


                    <div className="sidebar-contact-area">
                        <div className="contact-info">
                            <div className="contact-info-content">
                                <div>
                                    <img src="../../assets/img/about2.png"/>
                                </div>
                                <br/>
                                <h2>
                                    <a href="tel:+0881306298615">+995 555 48 02 58</a>
                                    <span>OR</span>
                                    <a href="mailto:aronix@gmail.com">info@xcoder.ge</a>
                                    <br/>
                                    <br/>
                                    <a href="#">
                                        <img src="../../assets/img/load.png"/>
                                    </a>
                                </h2>

                            </div>
                        </div>
                    </div>

                    <span className="close-btn sidebar-modal-close-btn" onClick={()=> onClose()}>
                        <i className="flaticon-close"/>
                    </span>
                </div>
            </div>
        </>
    )
}

export default SidebarModal;
