import React from 'react';

import './header.css';
import {UseEvent} from "../../core/hooks/useEvent";
const Header = ({page}) =>{
    const ev = UseEvent();
    return (
        <div className="navbar-area">
            <div className="aronix-responsive-nav">
                <div className="container">
                    <div className="aronix-responsive-menu">
                        <div className="logo">
                            <a href="/">
                                <img src="../../assets/img/logo.png" alt="logo"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aronix-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <a className="navbar-brand" href="/">
                            <img src="../../assets/img/logo.png" alt="logo"/>
                        </a>

                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a href="/" className={`"nav-link ${page==="main"?'active':''}`}>მთავარი</a>
                                </li>

                                <li className="nav-item">
                                    <a href="/about" className={`"nav-link ${page==="about"?'active':''}`}>ჩვენს შესახებ</a>
                                </li>

                                <li className="nav-item">
                                    <a href="/contact" className={`"nav-link ${page==="contact"?'active':''}`}>საკონტაქტო</a>
                                </li>
                            </ul>

                            <div className="others-options" onClick={()=> ev.emit('sidebar', true)}>
                                <div className="burger-menu">
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Header;
