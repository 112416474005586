import React from 'react';
import './footer.css';
const Footer = () =>{

    return (
        <section className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>საკონტაქტო</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className="flaticon-phone-call"/>
                                    {/*<span>Mon to Fri : 12:00 - 07:00</span>*/}
                                    <a href="tel:1235421457852">+995 555 48 02 58</a>
                                </li>
                                <li>
                                    <i className="flaticon-email"/>
                                    {/*<span>ელ-ფოსტა</span>*/}
                                    <a href="mailto:aronix@gmail.com">info@xcoder.ge</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>ლინკები</h3>

                            <ul className="footer-quick-links">
                                <li><a href="/">მთავარი</a></li>
                                <li><a href="/about">ჩვენს შესახებ</a></li>
                                <li><a href="/contact">საკონტაქტო</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-6 col-md-6">
                            <p>© 2022 Xcoder.ge All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;
