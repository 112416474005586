import React from 'react';
import './about.css';

const About = () =>{

    return (
        <>
            <div className="page-title-area page-title-bg1">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>ჩვენს შესახებ</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-img2"><img src="../../assets/img/shape/2.svg" alt="image"/></div>
                <div className="shape-img3"><img src="../../assets/img/shape/3.svg" alt="image"/></div>
                <div className="shape-img4"><img src="../../assets/img/shape/4.png" alt="image"/></div>
                <div className="shape-img5"><img src="../../assets/img/shape/5.png" alt="image"/></div>
                <div className="shape-img7"><img src="../../assets/img/shape/7.png" alt="image"/></div>
                <div className="shape-img8"><img src="../../assets/img/shape/8.png" alt="image"/></div>
                <div className="shape-img9"><img src="../../assets/img/shape/9.png" alt="image"/></div>
                <div className="shape-img10"><img src="../../assets/img/shape/10.png" alt="image"/></div>
            </div>

            <section className="about-area ptb-100" style={{padding:'0px'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <img src="../../assets/img/about-img1.png" alt="image"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <span className="sub-title">ჩვენს შესახებ</span>
                                <h2>კრეატიული გადაწყვეტილებები თქვენი ბიზნესის გასაუმჯობესებლად!</h2>
                                <p>ჩვენი გუნდი ქმნის ტექნოლოგიურად და ვიზუალურად თანამედროვე, ნებისმიერი სირთულისა და ფუნქციონალის მქონე ვებ საიტებს.</p>
                                <p>ყოველი პროდუქტი ანალიზის, კვლევისა და მსჯელობის შემდეგ იქმნება. მზად ვართ, გავეცნოთ თქვენს საჭიროებებს, სურვილებს, გაგიწიოთ კონსულტაცია და რეკომენდირება, ერთად მივიღოთ გადაწყვეტილება, რომ დარწმუნდეთ, ნამდვილად გჭირდებათ თუ არა ესა თუ ის მომსახურება.</p>
                                <p>საშუალებას გაძლევთ გახდეთ უფრო კრეატიული, ნებისმიერი იდეა, რომელიც შენს წარმოსახვაში დაიბადება აქციო რეალობად. </p>
                                <p>ჩვენი მისიაა დავეხმაროთ კლიენტებს ბიზნესის შექმნასა და ზრდა -განვითარებასთან დაკავშირებული გამოწვევების ოპტიმალურად გადაწყვეტაში, ვუზრუნველყოთ მაღალი ხარისხის მომსახურება და ეს ყოველივე გავაკეთოთ პროფესიონალურად და დროულად.</p>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="shape-img3"><img src="../../assets/img/shape/3.svg" alt="image"/></div>
                <div className="shape-img2"><img src="../../assets/img/shape/2.svg" alt="image"/></div>
            </section>

            <br/>
            <br/>
            <br/>
            <br/>

            <section className="features-area ptb-100 pt-0">
                <div className="container">

                    <div className="tab features-list-tab">
                        <div className="tab_content">
                            <div className="tabs_item">
                                <div className="features-overview">
                                    <div className="overview-content">
                                        <div className="content">
                                            <h2>რატომ ჩვენ?</h2>
                                            <p>ჩვენი გუნდის თითოეული წევრი ვზრუნავთ ინფორმაციის სწორად მართვაზე, კონფიდენციალობასა და უსაფრთხოებაზე.</p>
                                            <p>სამყარო იცვლება, მომხმარებელი კი მითუმეტეს. ასევე მათი მოთხოვნები, უპირატესი სურვილები, სამომხმარებლო ქცევა, საჭიროებები. დინამიკა და სამყაროს განვითარების ტენდენციები ყველაფერს თავის კვალს ამჩნევს. შესაბამისად, უფრო და უფრო ვითარდება პროდუქტთა არჩევანი, ნაირსახეობა, და  შეთავაზებები.</p>
                                            <p>კონკურენციის პირობებში, რთულია ეძებო და კიდევ უფრო რთულია მიაგნო სწორედ იმას, რაც ნამდვილად გჭირდება, სიამაყით შეგვიძლია გითხრათ, რომ ჩვენი გუნდი ნამდვილად დაგეხმარებათ საკუთარი ადგილის დამკვიდრებაში ბაზარზე.</p>

                                            <ul className="features-list">
                                                <li><span><i className="flaticon-tick"/> კვალიფიციური გუნდი</span></li>
                                                <li><span><i className="flaticon-tick"/> მაღალი ხარისხი</span></li>
                                                <li><span><i className="flaticon-tick"/> სტაბილურობა</span></li>
                                                <li><span><i className="flaticon-tick"/> სიმარტივე და სისწრაფე</span></li>
                                                <li><span><i className="flaticon-tick"/> მომხმარებელზე ორიენტირება</span></li>
                                                <li><span><i className="flaticon-tick"/> კონფიდენციალობა და უსაფრთხოება</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="overview-image">
                                        <div className="image">
                                            <img src="../../assets/img/features-image/1.png" alt="image"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tabs_item">
                                <div className="features-overview">
                                    <div className="overview-image">
                                        <div className="image">
                                            <img src="../../assets/img/features-image/2.png" alt="image"/>
                                        </div>
                                    </div>

                                    <div className="overview-content">
                                        <div className="content">
                                            <span className="sub-title">Define Your Choices</span>
                                            <h2>Product Design</h2>
                                            <p>We believe brand interaction is key in communication. Real innovations
                                                and a positive customer experience are the heart of successful
                                                communication. No fake products and services. The customer is king,
                                                their lives and needs are the inspiration.</p>

                                            <ul className="features-list">
                                                <li><span><i className="flaticon-tick"/> Open Source</span></li>
                                                <li><span><i className="flaticon-tick"/> Optimal Choice</span></li>
                                                <li><span><i className="flaticon-tick"/> High Security</span></li>
                                                <li><span><i className="flaticon-tick"/> Great Advices</span></li>
                                                <li><span><i className="flaticon-tick"/> Creative Layout</span></li>
                                                <li><span><i className="flaticon-tick"/> Super Responsive</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tabs_item">
                                <div className="features-overview">
                                    <div className="overview-content">
                                        <div className="content">
                                            <span className="sub-title">Define Your Choices</span>
                                            <h2>Digital Marketing</h2>
                                            <p>We believe brand interaction is key in communication. Real innovations
                                                and a positive customer experience are the heart of successful
                                                communication. No fake products and services. The customer is king,
                                                their lives and needs are the inspiration.</p>

                                            <ul className="features-list">
                                                <li><span><i className="flaticon-tick"/> Open Source</span></li>
                                                <li><span><i className="flaticon-tick"/> Optimal Choice</span></li>
                                                <li><span><i className="flaticon-tick"/> High Security</span></li>
                                                <li><span><i className="flaticon-tick"/> Great Advices</span></li>
                                                <li><span><i className="flaticon-tick"/> Creative Layout</span></li>
                                                <li><span><i className="flaticon-tick"/> Super Responsive</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="overview-image">
                                        <div className="image">
                                            <img src="../../assets/img/features-image/3.png" alt="image"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tabs_item">
                                <div className="features-overview">
                                    <div className="overview-image">
                                        <div className="image">
                                            <img src="../../assets/img/features-image/4.png" alt="image"/>
                                        </div>
                                    </div>

                                    <div className="overview-content">
                                        <div className="content">
                                            <span className="sub-title">Define Your Choices</span>
                                            <h2>Branding</h2>
                                            <p>We believe brand interaction is key in communication. Real innovations
                                                and a positive customer experience are the heart of successful
                                                communication. No fake products and services. The customer is king,
                                                their lives and needs are the inspiration.</p>

                                            <ul className="features-list">
                                                <li><span><i className="flaticon-tick"/> Open Source</span></li>
                                                <li><span><i className="flaticon-tick"/> Optimal Choice</span></li>
                                                <li><span><i className="flaticon-tick"/> High Security</span></li>
                                                <li><span><i className="flaticon-tick"/> Great Advices</span></li>
                                                <li><span><i className="flaticon-tick"/> Creative Layout</span></li>
                                                <li><span><i className="flaticon-tick"/> Super Responsive</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tabs_item">
                                <div className="features-overview">
                                    <div className="overview-content">
                                        <div className="content">
                                            <span className="sub-title">Define Your Choices</span>
                                            <h2>Development</h2>
                                            <p>We believe brand interaction is key in communication. Real innovations
                                                and a positive customer experience are the heart of successful
                                                communication. No fake products and services. The customer is king,
                                                their lives and needs are the inspiration.</p>

                                            <ul className="features-list">
                                                <li><span><i className="flaticon-tick"/> Open Source</span></li>
                                                <li><span><i className="flaticon-tick"/> Optimal Choice</span></li>
                                                <li><span><i className="flaticon-tick"/> High Security</span></li>
                                                <li><span><i className="flaticon-tick"/> Great Advices</span></li>
                                                <li><span><i className="flaticon-tick"/> Creative Layout</span></li>
                                                <li><span><i className="flaticon-tick"/> Super Responsive</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="overview-image">
                                        <div className="image">
                                            <img src="../../assets/img/features-image/5.png" alt="image"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tabs_item">
                                <div className="features-overview">
                                    <div className="overview-image">
                                        <div className="image">
                                            <img src="../../assets/img/features-image/6.png" alt="image"/>
                                        </div>
                                    </div>

                                    <div className="overview-content">
                                        <div className="content">
                                            <span className="sub-title">Define Your Choices</span>
                                            <h2>Marketing</h2>
                                            <p>We believe brand interaction is key in communication. Real innovations
                                                and a positive customer experience are the heart of successful
                                                communication. No fake products and services. The customer is king,
                                                their lives and needs are the inspiration.</p>

                                            <ul className="features-list">
                                                <li><span><i className="flaticon-tick"/> Open Source</span></li>
                                                <li><span><i className="flaticon-tick"/> Optimal Choice</span></li>
                                                <li><span><i className="flaticon-tick"/> High Security</span></li>
                                                <li><span><i className="flaticon-tick"/> Great Advices</span></li>
                                                <li><span><i className="flaticon-tick"/> Creative Layout</span></li>
                                                <li><span><i className="flaticon-tick"/> Super Responsive</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-img7"><img src="../../assets/img/shape/7.png" alt="image"/></div>
                <div className="shape-img2"><img src="../../assets/img/shape/2.svg" alt="image"/></div>
                <div className="shape-img3"><img src="../../assets/img/shape/3.svg" alt="image"/></div>
                <div className="shape-img4"><img src="../../assets/img/shape/4.png" alt="image"/></div>
            </section>

        </>
    )
}

export default About;
