import React from 'react';

const main = React.lazy(() => import(("./screens/main/mainScreen")));
const Redirect = React.lazy(() => import(("./components/redirect/redirect")));
const about = React.lazy(() => import(("./screens/about/aboutScreen")));
const contact = React.lazy(() => import(("./screens/contact/contactScreen")));


export const userRoutes = [
    { path: '/', name: 'Home', exact:true, component: main , page:"main"},
    //{ path: '/:lang', name: 'Home', exact:true, component: main , page:"main"},

    { path: '/:lang/main', name: 'Home', exact:true, component: main, page:"main" },
    //{ path: '/:lang/main/:params', name: 'Home', exact:true, component: main , page:"main"},

    { path: '/about', name: 'About', exact:true, component: about, page:"about" },
    //{ path: '/:lang/about', name: 'About', exact:false, component: about, page:"about" },

    { path: '/contact', name: 'Contact', exact:true, component: contact, page:"contact" },
    //{ path: '/:lang/contact', name: 'Contact', exact:false, component: contact, page:"contact" },

];




