import React, {useEffect, useState} from "react";
import './App.css';

import {MainNavigator, SidebarModal} from "./components";
import {UseEvent} from "./core/hooks/useEvent";
//import MainScreen from "./screens/main/mainScreen";

function App() {
    const event = UseEvent();
    const [showSideBar,setSowSideBar] = useState(false);

    useEffect(()=>{
        const sidebar= event.subscribe("sidebar",setSowSideBar)
        return ()=>{
            sidebar.unsubscribe()
        }
    },[])

  return (
    <>
        <MainNavigator/>

        {/*<MainScreen/>*/}


        {/*<Preloader/>*/}
        {
            <SidebarModal show={showSideBar} onClose={()=>setSowSideBar(false)}/>
        }
        <div className="go-top"><i className="fas fa-chevron-up"/></div>

    </>



  );
}

export default App;
