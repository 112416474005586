import React from 'react';

const FeaturesArea = () =>{

    return (
        <>
            <section className="features-area ptb-100 pt-0">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">ჩვენი სერვისები</span>
                        <h2>რას გთავაზობთ?</h2>
                    </div>

                    <div className="tab features-list-tab">
                        <ul className="tabs">
                            <li><a href="#" className="bg-fa7070">
                                <i className="flaticon-achievement"/>
                                <span>კონსულტაცია</span>
                            </a></li>

                            <li><a href="#" className="bg-c679e3">
                                <i className="flaticon-digital-marketing"/>
                                <span>პროექტის მენეჯმენტი</span>
                            </a></li>

                            <li><a href="#">
                                <i className="flaticon-data"/>
                                <span>საიტის დამზადება</span>
                            </a></li>

                            <li><a href="#" className="bg-00aeff">
                                <i className="flaticon-architecture"/>
                                <span>მობილური აპლიკაციის დამზადება</span>
                            </a></li>

                            <li><a href="#" className="bg-f78acb">
                                <i className="flaticon-research"/>
                                <span>საძიებო სისტემებში ოპტიმიზაცია</span>
                            </a></li>

                            <li><a href="#" className="bg-eb6b3d">
                                <i className="flaticon-analytics"/>
                                <span>პროექტის ტექნიკური განვითარება</span>
                            </a></li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img7"><img src="../../assets/img/shape/7.png" alt="image"/></div>
                <div className="shape-img2"><img src="../../assets/img/shape/2.svg" alt="image"/></div>
                <div className="shape-img3"><img src="../../assets/img/shape/3.svg" alt="image"/></div>
                <div className="shape-img4"><img src="../../assets/img/shape/4.png" alt="image"/></div>
            </section>
        </>
    )
}

export default FeaturesArea;
